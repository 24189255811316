import { useTranslation, WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import React, { FC } from 'react';
import { Button } from 'wix-ui-tpa';
import settingsParams from '../settingsParams';
import { classes, st } from './Widget.st.css';

export type ControllerProps = {
  handlers: {
    onClickOld: () => void;
    onClick: () => void;
  };
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  handlers: { onClickOld, onClick },
}: ControllerProps) => {
  const settings = useSettings();
  const { t } = useTranslation();

  return (
    <div className={st(classes.root, {})} data-hook="Button-wrapper">
      <div className={classes.header}>
        <h2 data-hook="app-title">
          {t('app.widget.welcome')} {settings.get(settingsParams.greetingsText)}
          !
        </h2>
      </div>
      <Button className={classes.mainButton} onClick={() => onClickOld()}>
        Click me - APeS
      </Button>

      <Button className={classes.mainButton} onClick={() => onClick()}>
        Click me - new system
      </Button>
    </div>
  );
};

export default Widget;
